@import "../../../assets/common/colors.scss";
@import "../../../assets/common/mixins.scss";

.empty-state-container {
  height: 50vh;
  .icon {
    margin-bottom: 16px;
  }
  .info {
    p {
      &:first-child {
        @include text(600, 16px, 24px, $spb-800-color);
        margin: 0;
        margin-bottom: 8px;
        text-align: center;
        letter-spacing: 0.2px;
      }

      &:last-child {
        @include text(normal, 16px, 16px, $primaryColor);
        text-align: center;
        letter-spacing: -0.05px;
        margin: 0;
      }
    }
  }
}
