@import "../../../assets/common/colors.scss";
@import "../../../assets/common/variables.scss";
@import "../../../assets/common/mixins.scss";

.alert-modal-container {
  // background: #ffffff;
  border: 1px solid rgba(22, 42, 86, 0.05);
  box-sizing: border-box;
  box-shadow: 0px 7.52055px 16.7123px rgba(171, 180, 189, 0.23);
  border-radius: 4px;

  .modal-content {
    max-width: 400px;
    margin: auto;

    .modal-header {
      margin-left: 1.3rem;
      margin-right: 1.3rem;
      padding: 1rem 0;

      .close {
        padding: 0 !important;
        margin: 0 !important;

        &:focus {
          outline: none;
        }
      }
    }

    .modal-body {
      padding-bottom: 32px;
    }
  }

  .modal-footer {
    border-top: none !important;
    padding: 0 24px 32px 24px;
  }
}
