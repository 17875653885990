@import "../../../assets/common/colors.scss";
@import "../../../assets/common/variables.scss";
@import "../../../assets/common/mixins.scss";

.pms-modal-container {
  border: 1px solid rgba(22, 42, 86, 0.05);
  box-sizing: border-box;
  box-shadow: 0px 7.52055px 16.7123px rgba(171, 180, 189, 0.23);
  border-radius: 4px;
  .modal-width {
    min-width: 730px;
  }
  .modal-content {
    // max-width: 560px;
    margin: auto;

    .modal-header {
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      padding: 1rem 0;
      border-bottom: none;
      .modal-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        /* identical to box height, or 133% */

        letter-spacing: 0.25px;

        /* SPN 900 */

        color: #0d0f11;
      }
      .close {
        padding: 0 !important;
        margin: 0 !important;

        &:focus {
          outline: none;
        }
      }
    }
  }

  .header-border {
    width: 92%;
    height: 0px;
    color: #d7dce0;
    margin: auto;
  }
  .modal-body {
    padding: 0;
  }

  .modal-footer {
    border-top: none !important;
    margin: 0.5rem 1.5rem 2rem 1.5rem;
    padding: 0 0.5px;
  }
}
