@import "~normalize.css";
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~font-awesome/css/font-awesome.min.css";
@import "~remixicon/fonts/remixicon.css";

@import "./assets/common/colors.scss";
@import "./assets/common/variables.scss";
@import "./assets/common/mixins.scss";
@import "./assets/scss/typography.scss";
@import "./assets/scss/input-field.scss";
@import "./assets/scss/tooltips.scss";

// Css-module
@import "/node_modules/vgg-css-modules/styles.min.css";

/* latin */

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: local("Inter"), local("Inter"), url(./assets/fonts/Inter-Medium.ttf) format("truetype");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #1354d3 $sideNavBgColor;
}
/* width */
*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px $sideNavBgColor;
  border-radius: 5px;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: #1354d3;
  border-radius: 5px;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: #0e347e;
}

body {
  margin: 0;
  font-family: "Inter", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

section {
  @include section(40px 32px 0 32px);
}

.main-section-div {
  padding: 0;
}

.color-primary {
  color: $spb-600-color;
}

.color-accent {
  color: #32de8a;
}

.color-red {
  color: #ff0000;
}

.color-white {
  color: #ffffff;
}

.bg-primary {
  /* background-color: #34495e; */
  background-color: #013444;
  min-height: 100vh;
}

.content-header {
  @include content-header;
}

.cursor {
  cursor: pointer;
}

.card-content {
  margin-top: 15px;
}

.btn-success {
  background-color: $greenish;
  border: none;
}
.btn-primary {
  background-color: #5d9cec;
  border: none;
}

.nav-tabs {
  .nav-item {
    @include text(500, 14px, 34px, $textColor);
  }
  .nav-link.active {
    @include text(700, 14px, 34px, $greenish);
  }
}

button {
  @include text(600, 16px, 24px, $white);
  padding: 16px 12px;
}

.content-header span:first-child {
  @include text(600, 28px, 32px, $spb-800-color);
}

.tab-content {
  padding: 24px 0px;
}

table {
  thead {
    th {
      @include text(600, 14px, 16px, $primaryColor);
      letter-spacing: 0.2%;
      padding: 24px 0.75rem !important;
      border-top: none !important;
    }
    background-color: $tableHeaderColor;
  }

  tbody {
    tr {
      th,
      td {
        @include text(400, 14px, 16px, $primaryColor);
        letter-spacing: 0.4px;
        padding: 20px 0.75rem !important;
      }
    }
  }
}
.bordered {
  border-bottom: 0.01rem solid #bac2ca !important;
}
.bordered-top {
  border-top: 1px solid #bac2ca !important;
}
.sub-top-btn {
  display: flex;
}
textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active {
  // outline: 0px !important;
  // -webkit-appearance: none;
  box-shadow: none !important;
}

::placeholder {
  font-size: 14px;
}

.cursor {
  cursor: pointer;
}

.sp-button--primary-green:disabled {
  background-color: $greenish;
  cursor: not-allowed;
}

.sp-button-height {
  height: 40px;
}

.shimmer-line {
  width: 100%;
  height: 8px;
  align-self: center;
  border-radius: 8px;
}

.page-nav > li.item-active > a:focus {
  outline: none !important;
}

.nav-tabs {
  border-bottom: 1px solid #d7dce0 !important;
  height: 40px;
  .nav-link {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.2px;
    opacity: 1;
    color: $spn-400-color;
  }
  .nav-link.active {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.2px;
    opacity: 1;
    color: $spb-600-color;
  }
  .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: $spb-600-color;
    background-color: #ffffff;
    border-color: #dee2e6 #dee2e6 #ffffff;
    border-bottom: 2px solid $spb-600-color;
  }
  .nav-link {
    border: none;
  }
}

.ant-select {
  font-size: 16px !important;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  letter-spacing: -0.05px;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: $spg-700-color !important;
  border-right-width: 1px !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: $spg-700-color !important;
  border-right-width: 1px !important;
  box-shadow: none !important;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  border-radius: 4px !important;
  border: 1px solid $offWhite !important;
}

.ant-select-selection-item,
.ant-select-selection-placeholder {
  align-self: center !important;
}
.ant-select-selection-placeholder {
  font-size: 16px;
  letter-spacing: -0.05px;
  color: #677684;
}

.ant-select-selection-search-input {
  padding-top: 15px !important;
}

.user-label {
  font-weight: 600;
  letter-spacing: 0.2px;
}
.user-value {
  letter-spacing: -0.048px;
}

.user-value,
.user-label {
  font-size: 16px;
  line-height: 24px;
  color: $primaryColor;
}

.bx--text-input:disabled,
.bx--text-input[readonly],
.form-control:disabled,
.form-control[readonly] {
  background: #f4f5f6 !important;
  color: #677684 !important;
  letter-spacing: -0.05px !important;
}

.tableTopBtn {
  display: flex;
  justify-content: flex-end;
}

.search-bar {
  margin-right: 16px;
}

.table-avatar {
  vertical-align: middle;
  height: 32px;
  width: 32px;
  border-radius: 50%;
}

// .table-responsive {
//   border-bottom: 1px solid #d7dce0;
//   // margin: 0 1rem;
// }
.ri-checkbox-fill,
.ri-checkbox-indeterminate-fill {
  color: $spb-600-color;
}

.bx--text-input {
  background-color: #fff;
  border-bottom: none;
  outline: none;
}

.bx--text-input:focus,
.bx--text-input:active {
  outline: none;
}

.bx--list-box {
  background-color: #fff;
  border-bottom: none;
  border: 1px solid $offWhite;
  border-radius: 4px;

  &:focus,
  &:active {
    border: 2px solid $spb-500-color;
  }
}
.bx--combo-box .bx--list-box__field {
  padding: 1px;
}
.bx--list-box__field {
  border-bottom: none;
}

.bx--list-box__selection--multi {
  background-color: $spb-600-color;
}

.bx--checkbox:checked + .bx--checkbox-label::before {
  border-color: $spb-600-color;
  background-color: $spb-600-color;
}

.bx--list-box__menu {
  border: 1px solid #d7dce0;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #fff;
  min-width: 200px !important;
}
.bx--list-box__menu-item__option {
  border-top: none;
  border-bottom: none;
}
.bx--list-box__menu-item--highlighted,
.bx--list-box__menu-item--active {
  background-color: $sideNavBgColor;
}

.css-db29w9-option {
  background-color: $sideNavBgColor !important;

  &:active {
    background-color: $sideNavBgColor !important;
  }
}

.chips-control {
  label + div {
    min-height: 48px !important;
    background: #ffffff !important;
    /* SPN 100 */
    font: 13.3333px Inter !important;
    border: 1px solid #d7dce0 !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;

    ul {
      border: 1px solid #d7dce0 !important;
      box-sizing: border-box !important;
      border-radius: 4px !important;
      max-height: 256px !important;

      li {
        padding: 12px 15px !important;
        display: flex !important;
        align-items: center !important;
        background-color: #fff !important;
        &:hover {
          background: #f4f5f6 !important;
        }
      }
    }
  }
}

.form-control {
  border-radius: 4px !important;
  border: 1px solid $offWhite !important;
  box-sizing: border-box !important;
  color: #495057;
  &:focus {
    border: 2px solid $spb-600-color !important;
    box-shadow: none !important;
  }

  .was-validated &:invalid,
  &.is-invalid {
    border-color: #dc3545 !important;
  }
}

.form-label {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: $primaryColor;
  margin-bottom: 8px;
}

.form-control {
  height: 48px !important;
}
::placeholder {
  padding: 10px 0;
}

section {
  padding: 0 10px;
}

.form-control::placeholder {
  // &::placeholder,
  // &:-ms-input-placeholder,
  // &::-ms-input-placeholder {
  // }
  @include text(normal, 16px, 16px, #81909d);
  letter-spacing: -0.05px;
}

.css-1wa3eu0-placeholder {
  color: #81909d !important ;
  letter-spacing: -0.05px !important;
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  @include text(normal, 16px, 16px, #81909d);
  letter-spacing: -0.05px;
}

.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  @include text(normal, 16px, 16px, #81909d);
  letter-spacing: -0.05px;
}

.apexcharts-tooltip {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  letter-spacing: 0.4px;
}
.apexcharts-tooltip.apexcharts-theme-dark {
  background: #24292e !important;
}
.apexcharts-tooltip.apexcharts-theme-dark .apexcharts-tooltip-title {
  background: #24292e !important;
  border-bottom: none !important;
}

.apexcharts-tooltip-marker {
  border-radius: 1px;
}

.apexcharts-legend.position-bottom.apexcharts-align-center {
  justify-content: start !important;
}

.apexcharts-legend-marker {
  border-radius: 2px !important;
}

.progress {
  .progress-bar {
    margin-right: 2px;
    border-radius: 2px;
  }

  .bg-atRisk {
    background-color: $spr-600-color !important;
  }

  .bg-pending {
    background-color: $spn-100-color !important;
  }

  .bg-behind {
    background-color: $spy-600-color !important;
  }

  .bg-notStarted {
    background-color: $spr-300-color !important;
  }

  .bg-onTrack {
    background-color: $spg-600-color !important;
  }

  .bg-completed {
    background-color: $spt-600-color !important;
  }

  div:last-child {
    margin-right: 0;
  }
  .flex-and-space-between {
    display: flex;
    justify-content: space-between;
  }
}

.apexcharts-tooltip {
  // width: 156px;

  .apexcharts-tooltip-text {
    display: flex;
    width: 100%;
  }

  .apexcharts-tooltip-y-group {
    display: flex;
    justify-content: space-between;
    width: 130px;

    .apexcharts-tooltip-text-label {
      width: 40% !important;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
