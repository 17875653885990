@import "../../../assets/common/colors.scss";
@import "../../../assets/common/variables.scss";
@import "../../../assets/common/mixins.scss";

.table-loader-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.detail-loader {
  margin-top: -300px;
  margin-bottom: -350px;
}

.loading-dropdown {
  display: block;
  margin-top: -10px;
  @include text(400, 14px, 16px, $appColor);
}
