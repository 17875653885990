@import "../../../assets/common/colors.scss";
@import "../../../assets/common/variables.scss";
@import "../../../assets/common/mixins.scss";

#team-editor {
  .nav-tabs {
    .nav-link {
      width: 50% !important;
      text-align: center;
    }
  }

  .modal-header {
    border-bottom: none !important;
  }

  .modal-body {
    margin: 0 24px 16px 24px !important;
  }
  .mb-24 {
    margin-bottom: 24px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .tab-content {
    padding: 24px 0 0 0;
  }

  .team-editor-content {
    width: 560px !important;
  }
}
