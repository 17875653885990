@import "../../assets/common/mixins.scss";
@import "../../assets/common/partials/colors";

.home-container {
  .name {
    @include text(600, 24px, 24px, $spb-800-color);
  }
  .sub-title {
    margin-top: 12px;
    @include text(600, 14px, 16px, $spb-900-color);
    .divider {
      padding-right: 16px;
      padding-left: 16px;
      display: inline-block;
    }
  }
}
