@import "../../../../assets/common/colors.scss";
@import "../../../../assets/common/variables.scss";
@import "../../../../assets/common/mixins.scss";

.invite-user-editor-content {
  // margin: 0 24px;
  .nav-tabs {
    .nav-link {
      width: 50% !important;
      text-align: center;
    }
  }

  .table-responsive {
    max-height: 480px;
  }

  textarea.form-control::placeholder {
    padding-top: 16px !important;
  }

  .modal-header {
    border-bottom: none !important;
  }

  .modal-body {
    margin: 0 24px 32px 24px !important;
  }

  .tab-content {
    padding: 16px 0 0 0;
  }

  .team-editor-content {
    width: 560px !important;
  }

  .success {
    @include text(normal, 14px, 16px, #1e944d);
    letter-spacing: 0.4px;
  }
  .failure {
    @include text(normal, 14px, 16px, #ca1b1b);
    letter-spacing: 0.4px;
  }

  .mb-20 {
    margin-bottom: 20px;
  }
  .mb-10 {
    margin-bottom: 10px;
  }
}
