@import "../../../assets/common/mixins.scss";
@import "../../../assets/common/partials/colors";

.change-password-container {
  padding: 16px 24px 0 24px;
  label {
    @include text(normal, 14px, 16px, $spn-700-color);
  }
  .confirm-password {
    padding-bottom: 24px;
  }
  .show-password {
    position: absolute;
    right: 30px;
    margin-top: 44px;
    cursor: pointer;
    @include text(normal, 16px, 16px, $spb-600-color);
  }
}
