@import '../../../assets/common/partials/spacing';
@import '../../../assets/common/partials/colors';
@import '../../../assets/common/colors';
@import '../../../assets/common/partials/fonts';

/**************************************
***************BASE BUTTON***********
************************************************/

.sp-button {
  font-weight: $sp-fontweight--600;
  border-radius: $br-4;
  font-style: normal;
  border-color: unset;
  border-style: none;

  &:focus {
    outline: none;
  }
}

/**************************************
***************DISABLED STATE*********
**************************************/

@mixin disabled-primary {
  &:disabled {
    color: $spn-400-color;
    background-color: $spn-100-color;
    cursor: not-allowed;

    &:hover {
      background-color: $spn-100-color;
    }
  }
}

@mixin disabled-outline {
  &:disabled {
    color: $spn-400-color;
    cursor: not-allowed;
    border-color: $spn-100-color;

    &:hover {
      background-color: $spn-100-color;
    }
  }
}

@mixin disabled-ghost {
  &:disabled {
    color: $spn-400-color;
    cursor: not-allowed;

    &:hover {
      background-color: $spn-100-color;
    }
  }
}

/**************************************
***************BUTTON SIZES***********
*************************************/

.sp-button {
  &--lg {
    font-size: $sp-fontsize--18;
    line-height: $sp-lineheight--20;
    padding: $padding-18 $padding-40;
  }

  &--md {
    font-size: $sp-fontsize--16;
    line-height: $sp-lineheight--24;
    padding: $padding-12 $padding-32;
  }

  &--sm {
    font-size: $sp-fontsize--14;
    line-height: $sp-lineheight--16;
    padding: $padding-12 $padding-24;
  }
}

/*****************************************************************
*******BUTTONS WITH BACKGROUND (PRIMARY BUTTONS)******************
*****************************************************************/

.sp-button--primary {
  border: none;

  &-blue {
    color: $spn-000-color;
    background-color: $spb-600-color;
    &:hover {
      background-color: $spb-400-color;
    }
    @include disabled-primary;
  }

  &-green {
    color: $spn-000-color;
    // background-color: $spg-600-color;
    background-color: #1e944d;
    &:hover {
      background-color: $spg-400-color;
    }
    @include disabled-primary;
  }

  &-orange {
    color: $spn-000-color;
    background-color: $spo-600-color;
    &:hover {
      background-color: $spo-400-color;
    }
    @include disabled-primary;
  }

  &-red {
    color: $spn-000-color;
    background-color: $spr-600-color;
    &:hover {
      background-color: $spr-400-color;
    }
    @include disabled-primary;
  }

  &-destructive {
    color: $spn-000-color;
    background-color: $spr-500-color;
    @include disabled-primary;
  }
}

/***************************************************************
*************OUTLINE BUTTONS (SECONDARY BUTTONS) ***************
****************************************************************/

.sp-button--outline {
  &-blue {
    border: 1px solid $spb-600-color;
    color: $spb-600-color;
    background-color: $spn-000-color;
    &:hover {
      background-color: $spb-200-color;
    }
    @include disabled-outline;
  }

  &-green {
    border: 1px solid $spg-600-color;
    color: $spg-600-color;
    // background-color: #1e944d;
    background-color: $spn-000-color;
    &:hover {
      background-color: $spg-200-color;
    }
    @include disabled-outline;
  }

  &-orange {
    border: 1px solid $spo-500-color;
    color: $spo-500-color;
    background-color: $spn-000-color;
    &:hover {
      background-color: $spo-200-color;
    }
    @include disabled-outline;
  }

  &-purple {
    border: 1px solid $spp-500-color;
    color: $spp-500-color;
    background-color: $spn-000-color;
    &:hover {
      background-color: $spp-200-color;
    }
    @include disabled-outline;
  }

  &-teal {
    border: 1px solid $spt-600-color;
    color: $spt-600-color;
    background-color: $spn-000-color;
    &:hover {
      background-color: $spt-200-color;
    }
    @include disabled-outline;
  }

  &-neutral {
    border: 1px solid $spn-700-color;
    color: $spn-700-color;
    background-color: $spn-000-color;
    &:hover {
      background-color: $spn-200-color;
    }
    @include disabled-outline;
  }

  &-red {
    border: 1px solid $spr-500-color;
    color: $spr-500-color;
    background-color: $spn-000-color;
    &:hover {
      background-color: $spr-200-color;
    }
    @include disabled-outline;
  }
}

/*****************************************************
*************GHOST BUTTON (BUTTON TEXT)***************
******************************************************/

.sp-button--ghost {
  &-green {
    color: $spg-600-color;
    background-color: $spn-000-color;
    &:hover {
      background-color: $spg-200-color;
    }

    @include disabled-ghost;
  }

  &-blue {
    color: $spb-600-color;
    background-color: $spn-000-color;
    &:hover {
      background-color: $spb-200-color;
    }

    @include disabled-ghost;
  }

  &-orange {
    color: $spo-600-color;
    background-color: $spn-000-color;
    &:hover {
      background-color: $spo-200-color;
    }

    @include disabled-ghost;
  }

  &-normal {
    color: $primaryColor;
    background-color: $spn-000-color;
    &:hover {
      background-color: $spb-200-color;
    }

    @include disabled-ghost;
  }
}

/**************************************
***************ICON BUTTONS***********
*************************************/

.sp-button--icon {
  i {
    padding-left: $padding-8;
    vertical-align: middle;
  }
}

/**************************************
***************STAND-ALONE  ICON BUTTONS***********
*************************************/

.sp-button--icon-standalone {
  border-radius: 50%;
  position: relative;

  &-xs {
    padding: 10px;
  }

  &-sm {
    padding: 15px;
  }

  &-md {
    padding: 20px;
  }

  &-lg {
    padding: 30px;
  }

  .icon {
    position: absolute;
    transform: translate(-50%, -50%);
  }
}
