@import "../../assets/common/colors.scss";

.sidebar-fixed {
  position: fixed !important;
  z-index: 4;
}
.routes {
  margin-left: 220px;
}
@media screen and (max-width: 808px) {
  .m-margin {
    margin-top: 20px;
  }
  .routes {
    margin-left: 50px;
  }
}
