h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.App-page {
  width: 500px;
  margin: 0 auto;
}
