@import "../../../../assets/common/mixins.scss";
@import "../../../../assets/common/partials/colors";

.objective-details-container {
  .team-select {
    width: 240px;
    height: 40px;
  }
  .td-action {
    @include text(normal, 16px, 16px, $spn-700-color);
  }
  .pg-header {
    margin-bottom: 8px;
  }

  .td-title {
    @include text(500, 14px, 16px, $spn-900-color);
  }
  .td-subtitle {
    margin-top: 8px;
    @include text(normal, 12px, 16px, $spn-500-color);
  }
  .td-date {
    letter-spacing: 0.4px;
    @include text(normal, 14px, 16px, $spn-900-color);
  }
  .td-status {
    cursor: pointer;
    text-decoration-line: underline;
    @include text(600, 14px, 16px, $spb-600-color);
  }

  .label {
    @include text(600, 18px, 16px, $spn-700);
    .mr {
      margin-right: 9.33px;
    }
  }
  .label > span {
    @include text(600, 14px, 16px, $spn-700);
  }
  .label-text {
    letter-spacing: 0.4px;
    margin-top: 8px;
    @include text(normal, 12px, 16px, #3a434b);
  }
  .sub-items {
    margin-top: 32px;
  }

  .check-in {
    cursor: pointer;
  }

  .form-control {
    height: 40px !important;
  }
  table {
    margin-top: 24px;
    thead {
      background-color: $white;
      @include text(600, 14px, 16px, $spn-400-color);
      tr {
        height: 48px;
      }
    }
    .title-header {
      width: 50%;
    }
  }
  .empty-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 100px;
    .no-goals {
      margin: 12px 0 8px 0;
      @include text(600, 16px, 24px, $spb-800-color);
    }
    .add-goal {
      @include text(normal, 16px, 16px, $spb-700-color);
    }
  }

  .tip.left {
    right: 125%;
  }

  .no-data {
    padding-left: 40px !important;
    padding-top: 0 !important;
    font-style: italic;
    font-size: 12px;
  }
}
.kr-back {
  @include text(600, 16px, 24px, $spn-700-color);
  letter-spacing: 0.2px;
  cursor: pointer;
}
