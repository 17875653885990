@import "../../../assets/common/mixins.scss";
@import "../../../assets/common/partials/colors";

.line-report-container {
  .main-container {
    border: 1px solid #d7dce0;
    border-radius: 4px;
    margin-bottom: 24px;
  }

  .empty-content {
    text-align: center;
    margin-top: 25vh;
    width: 100%;
  }

  .table-avatar {
    vertical-align: middle;
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }

  .title-div {
    justify-content: space-between;
    padding: 24px 24px 16px 16px;
  }

  .avatar-div {
    display: flex;
    align-items: center;

    p:first-child {
      @include text(500, 16px, 16px, $spn-900-color);
    }
    p:nth-child(2) {
      @include text(500, 12px, 16px, $spn-700-color);
    }
  }

  .link-text {
    text-decoration: underline;
    @include text(500, 14px, 16px, $spb-600-color);
  }

  .link-text-blue {
    text-decoration: underline;
    @include text(600, 14px, 16px, $spb-600-color);
  }

  .link-text-red {
    text-decoration: underline;
    @include text(600, 14px, 16px, $spr-600-color);
  }

  .accordion-container {
    padding: 0;
    margin-bottom: 0;
  }

  .accordion-div {
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding: 0;

    .row {
      margin: 0;
      width: inherit;
    }
  }
  .accordionLabel {
    @include text(600, 16px, 26px, $spn-900-color);
  }

  .accordionLabelSubtext {
    // margin-left: 25px;
    span {
      color: $spn-500;
      font-size: 12px;
    }
  }

  .accordion .card {
    border-radius: 0;
    border-top: 1px solid #d7dce0;
    border-right: none;
    border-left: none;
    border-bottom: 0;
  }

  .accordion .card .card-header {
    padding: 0;
    background: none;
    height: 72px;
    padding: 12px 22px 16px 22px;
    border-bottom: 0;
  }

  .accordion .card .card-header > button {
    text-decoration: none;
    padding: 0;
    width: 100%;
  }

  .card-body {
    padding: 0;
    border-top: 1px solid #d7dce0;
  }

  .children-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px 24px 56px;
  }

  .children-container:hover {
    background-color: #f4f5f6;
  }

  .progress-mini {
    display: flex;
    height: 0.5rem;
    overflow: hidden;
    line-height: 0;
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 0.25rem;
  }
}

.comment-box {
  margin: 16px 0 24px 0;
  font-family: "inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #3a434b;
  opacity: 1;
}

.cancel-key {
  color: #3a434b;
}
