@import "../../../assets/common/mixins.scss";
@import "../../../assets/common/partials/colors";

.message-container {
  .message-content {
    padding: 0 32px 30px 48px;
  }
  .title {
    margin: 38px 0 24px 0;
    letter-spacing: 0.2px;
    @include text(600, 24px, 24px, $spb-800-color);
  }
  .description {
    @include text(normal, 16px, 24px, $spn-800-color);
  }

  .btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    .steps {
      @include text(normal, 14px, 16px, $spn-500-color);
    }
  }
  .empty-space {
    background: $spb-200-color;
    height: 100%;
  }
  .next {
    margin-left: 12px;
  }
}
.welcome-message-editor-content {
  width: 720px !important;
}
