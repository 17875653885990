//Component Spacing

$padding-2: 2px;
$padding-4: 4px;
$padding-8: 8px;
$padding-10: 10px;
$padding-12: 12px;
$padding-18: 18px;
$padding-16: 16px;
$padding-20: 20px;
$padding-24: 24px;
$padding-32: 32px;
$padding-40: 40px;
$padding-48: 48px;

//Frame Spacing
$margin-2: 2px;
$margin-4: 4px;
$margin-8: 8px;
$margin-10: 10px;
$margin-12: 12px;
$margin-16: 16px;
$margin-20: 20px;
$margin-24: 24px;
$margin-32: 32px;
$margin-40: 40px;
$margin-48: 48px;
$margin-56: 56px;
$margin-80: 80px;
$margin-120: 120px;
$margin-160: 160px;

//border radius
$br-4: 4px;
$br-8: 8px;
$br-12: 12px;
$br-16: 16px;

$cs-01: 2px;
$cs-02: 4px;
$cs-03: 8px;
$cs-04: 10px;
$cs-05: 12px;
$cs-06: 16px;
$cs-07: 20px;
$cs-08: 24px;
$cs-09: 32px;
$cs-10: 48px;

//Frame Spacing
$fs-01: 2px;
$fs-02: 4px;
$fs-03: 8px;
$fs-04: 10px;
$fs-05: 12px;
$fs-06: 16px;
$fs-07: 20px;
$fs-08: 24px;
$fs-09: 32px;
$fs-10: 56px;
$fs-11: 80px;
$fs-12: 120px;
$fs-13: 160px;

$cs__00: 4px;
$cs__01: 8px;
$cs__03: 16px;
