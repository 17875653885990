@import "../../../assets/common/mixins.scss";
@import "../../../assets/common/partials/colors";

.modal-sub-nav {
  min-height: 100vh;
  background: $spn-050-color;
  padding: 24px 108px 24px 24px;
  .draft {
    margin: 0 0 8px 0;
    @include text(600, 14px, 16px, $spn-700-color);
  }
  .draft-content {
    background-color: $white;
    padding: 12px;
    margin-bottom: 20px;

    .desc {
      margin: 0 0 8px 0;
      @include text(500, 14px, 16px, $spn-700-color);
    }
    .result-key {
      margin-bottom: 8px;
      @include text(normal, 12px, 16px, $spn-700-color);
    }
    .resume {
      @include text(600, 14px, 16px, $spb-600-color);
    }
  }
}
