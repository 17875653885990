@import "../../assets/common/mixins.scss";
@import "../../assets/common/partials/colors";

.profile-container {
  .profile-picture {
    margin: 24px 0 16px 0;
    @include text(600, 16px, 24px, $spn-900);
  }

  .profile-detail {
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    border-bottom: 1px solid $spn-100-color;

    .photo-detail {
      width: 332px;
      text-align: left;
      margin-left: 16px;

      .phote-desc {
        margin-top: 8px;
        @include text(normal, 14px, 24px, $spn-700-color);
        letter-spacing: 0.4px;
      }
    }
  }
  .detail-update {
    display: flex;
    justify-content: space-between;
  }
  .personal-detail {
    margin-top: 24px;
    @include text(600, 16px, 24px, $spn-900-color);
    letter-spacing: 0.2px;
  }
  label {
    margin-top: 9px;
    @include text(normal, 14px, 16px, $spn-700-color);
  }
  .time-zone {
    margin-top: 24px;
  }
  .other-team {
    margin-top: 7px;
    span {
      display: inline-block;
      padding: 4px 8px;
      @include text(normal, 14px, 16px, $spn-500-color);
      background: $spn-050-color;
      border-radius: 4px;
      margin-right: 8px;
    }
  }
  .account-info {
    margin-top: 34px;
    padding-top: 24px;
    border-top: 1px solid $spn-100-color;
  }
  .change-password {
    margin: 24px 0;
  }
  .update-btn {
    margin: 24px 0 6px 0;
  }
  #imageUpload {
    display: none;
  }
  .profile-image {
    height: 104px;
    width: 104px;
    border-radius: 50%;
  }
  .upload-photo {
    margin-bottom: 0;
    cursor: pointer;
    text-decoration: underline;
    letter-spacing: 0.2px;
    margin-right: 16px;
    @include text(600, 16px, 24px, $spb-600-color);
  }
  .cancel-photo {
    margin-bottom: 0;
    cursor: pointer;
    text-decoration: underline;
    letter-spacing: 0.2px;
    @include text(600, 16px, 24px, $spr-500-color);
  }
}
