.view-users-container {
  a.disableLink {
    color: gray;
    cursor: not-allowed;
    opacity: 0.5;
    text-decoration: none;
  }
  a.sp-button:hover {
    text-decoration: none;
  }
}
