@import "../../../assets/common/mixins.scss";
@import "../../../assets/common/partials/colors";

.registration-container {
  .details-content {
    max-width: 600px;
    min-height: 100vh;
    padding: 37px 80px;
    background: $spn-050-color;
  }
  .title {
    padding-top: 100px;
    @include text(600, 36px, 40px, $spb-800-color);
  }
  .sub-title {
    margin: 16px 0 32px 0;
    @include text(normal, 18px, 24px, $spn-800-color);
    letter-spacing: -0.048px;
  }
  .checked-item {
    letter-spacing: -0.05px;
    @include text(normal, 16px, 36px, $spn-700-color);
    margin-bottom: 4px;
    img {
      margin-right: 6px;
    }
  }
}
@media screen and (max-width: 981px) {
  .registration-container {
    .details-content {
      max-width: 50%;
      padding: 37px 30px;
    }
  }
}

@media screen and (max-width: 767px) {
  .registration-container {
    .details-content {
      display: none;
    }
  }
}
