@import "../../../assets/common/partials/colors";
@import "../../../assets/common/variables.scss";
@import "../../../assets/common/mixins.scss";

@mixin sidebar-hover($color, $opacity, $borderLeft, $paddingLeft: 10px) {
  background-color: $color;
  opacity: $opacity;
  border-left: $borderLeft;
  padding-left: $paddingLeft;
}
.sidebar-container {
  background-color: $spn-050-color;
  min-height: 100vh;
  padding-top: 102px;
  .nav-bar {
    ul {
      padding-left: 0;
      li {
        list-style: none;
      }
    }
    .nav-item {
      max-width: 218px;
      min-height: 38px;
      margin-top: 10px;
      z-index: 1000 !important;
      > a,
      > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: $primaryColor;
        cursor: pointer;
      }
      .nav-link {
        @include text(400, 14px, 16px, $spn-700-color);
      }
      .item-name {
        display: flex;
        justify-content: space-between;
        padding-left: 12px;
        min-width: 180px;
        margin-top: 4px;
        span {
          display: inline-block;
          text-align: right;
        }
      }
      .item-name-active {
        @include text(400, 14px, 16px, $spb-600-color);
      }
      .fa {
        padding-left: 10px;
        padding-right: 30px;
        width: 20px;
      }

      .nav-sub-item li {
        max-width: 218px;
        min-height: 38px;
        padding-top: 10px;
        padding-bottom: 10px;
        cursor: pointer;
        width: 126px;
        @include text(400, 14px, 16px, $textColor);
      }
      .nav-sub-item {
        background-color: $sideNavBgColor2;
        margin-left: 45px;
        padding: 0px 10px 0px 8px;
        li {
          padding-left: 0px;
        }
      }
      .show-nav-sub-item {
        display: block;
      }
      .close-nav-sub-item {
        display: none;
      }
      ul {
        list-style: none;
      }
    }
    .nav-collapse:hover {
      width: 180px;
      padding-right: 189px;
      background-color: $sideNavHoverColor;
    }

    .nav-collapse {
      .nav-link {
        padding-left: 15px;
      }
    }
    .active {
      z-index: 2000;
      padding-bottom: 10px;
      padding-left: 22px;
      @include sidebar-hover($spb-100-color, 1, 3px solid $greenish, 13px);
    }
  }
  .mobile-menu-item {
    display: none;
  }
  hr {
    background: $spn-200-color;
    /* SPN 200 */

    // border: 1px solid ;
    width: 85%;
    margin: auto;
    margin-top: 32px;
    margin-bottom: 32px;
  }
}

@media screen and (max-width: 808px) {
  .sidebar-container {
    background-color: $spn-050-color;
    min-height: 200vh;
    padding-top: 126px;
    margin-top: -20px;
    .mobile-menu-item {
      display: block;
      position: absolute;
      z-index: 4;
      margin-left: 40px;
      img {
        position: absolute;
        margin-left: -5px;
        margin-top: 8px;
      }
      span {
        background-color: $spb-800-color;
        padding: 8px;
        color: #ffffff;
        border-radius: 4px;
      }
    }
    .nav-bar {
      .nav-item {
        .item-name {
          display: none;
        }
        .fa {
          padding-left: 10px;
          padding-right: 30px;
          width: 20px;
        }
      }
    }
  }
}
