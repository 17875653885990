.reports-table {
  .resume {
    text-decoration: underline;
  }
}

.report-info {
  .empty-content {
    border: none;
  }
}

.one-on-one-container {
  .search-container {
    width: 304px;
  }
  .select-container {
    width: 168px;
  }
}
