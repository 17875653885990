.custom-timeline-form-container {
  .mb-24 {
    margin-bottom: 24px;
  }
  .mb-32 {
    margin-bottom: 32px;
  }

  .mt-24 {
    margin-top: 24px;
  }
}
