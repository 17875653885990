@import "../../../assets/common/mixins.scss";
@import "../../../assets/common/partials/colors";

:root {
  --tooltip-margin: 80px;
  --tooltip-arrow-size: 6px;
}

/* Wrapping */
.tooltip-wrapper {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

/* Absolute positioning */
.tip {
  position: absolute;
  border-radius: 4px;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px;
  background: $darkish;
  @include text(500, 12px, 16px, $spn-000-color);
  z-index: 100;
  white-space: nowrap;

  &::before {
    content: " ";
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: var(--tooltip-arrow-size);
    margin-left: calc(var(--tooltip-arrow-size) * -1);
  }
}

/* Absolute positioning */
.tip.top {
  top: calc(var(--tooltip-margin) * -1);
}
/* CSS border triangles */
.tip.top::before {
  top: 100%;
  border-top-color: $darkish;
}

/* Absolute positioning */
.tip.right {
  left: calc(100% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
/* CSS border triangles */
.tip.right::before {
  left: calc(var(--tooltip-arrow-size) * -1);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-right-color: $darkish;
}

/* Absolute positioning */
.tip.bottom {
  bottom: calc(var(--tooltip-margin) * -1);
}
/* CSS border triangles */
.tip.bottom::before {
  bottom: 100%;
  border-bottom-color: $darkish;
}

/* Absolute positioning */
.tip.left {
  left: auto;
  right: calc(100% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
/* CSS border triangles */
.tip.left::before {
  left: auto;
  right: calc(var(--tooltip-arrow-size) * -2);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-left-color: $darkish;
}
