@import "../../../assets/common/colors.scss";

/*style wrapper to give some space*/
.wrapper {
  position: relative;
  margin: 0;
}

/*style label to give some more space*/
.wrapper label {
  display: block;
  padding: 0px 0 0px 43.7px;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: -0.048px;

  /* SPN 700 */

  color: #3a434b;
}

/*style and hide original checkbox*/
.wrapper input {
  height: 40px;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 40px;
}

/*position new box*/
.wrapper input + label::before {
  border: 1px solid $sideNavBgColor;
  content: "";
  opacity: 1;
  height: 22.5px;
  left: 0em;
  position: absolute;
  top: 0em;
  bottom: 0em;
  right: 0em;
  width: 22.5px;
  border-radius: 50%;
}

/*radio pseudo element styles*/
.wrapper input + label::after {
  content: "";
  opacity: 1;
  border: 5.6px solid $sideNavBgColor;
  border-radius: 50%;
  position: absolute;
  left: 0.36em;
  top: 0.36em;
  // transition: opacity 0.2s ease-in-out;
}

/*reveal check for 'on' state*/
.wrapper input:checked + label::after {
  border: 5.6px solid $spb-600-color;
}
/*reveal check for 'on' state*/
.wrapper input:checked + label::before {
  border: 1px solid $spb-600-color;
}

/*focus styles*/
.wrapper input:focus + label::before {
  box-shadow: 0 0 0 1px $spb-400-color;
  outline: 1px solid transparent; /* For Windows high contrast mode. */
}
