@import "../../../../assets/common/mixins.scss";
@import "../../../../assets/common/colors.scss";

.user-role-editor-container {
  .form-control {
    border-radius: 4px !important;
    border: 1px solid $offWhite !important;
    box-sizing: border-box !important;
    color: #495057;
    &:focus {
      border: 2px solid $spb-600-color !important;
      box-shadow: none !important;
    }

    .was-validated &:invalid,
    &.is-invalid {
      border-color: #dc3545 !important;
    }
  }

  .sp-section-title {
    margin-bottom: 8px;
  }

  .form-label {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: $primaryColor;
    margin-bottom: 8px;
  }

  .form-control {
    height: 48px !important;
  }
  .info {
    margin-left: 3rem;
    @include text(normal, 14px, 24px, $primaryColor);
    letter-spacing: 0.4px;
  }
  .form-check-label {
    @include text(normal, 14px, 24px, $primaryColor);
    letter-spacing: -0.048px;
  }

  fieldset {
    legend {
      @include text(600, 16px, 24px, $textColor);
      letter-spacing: 0.2px;
    }
    .form-control {
      border: none !important;

      p {
        &:first-child {
          margin-left: 0 !important;
        }
      }
    }
  }
}
