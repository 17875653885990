@import "../../../../../assets/common/mixins.scss";
@import "../../../../../assets/common/partials/colors";

.no-contributions-container {
  .back-icon-container {
    cursor: pointer;
  }

  .pg-title {
    @include text(600, 24px, 24px, $spb-900-color);
    letter-spacing: 0.2px;
    margin-bottom: 16px;
    margin-top: 24px;
  }

  .td-title {
    @include text(500, 14px, 16px, $spn-900-color);
  }
  .td-subtitle {
    margin-top: 8px;
    @include text(normal, 12px, 16px, $spn-500-color);
  }

  .arrow-contain {
    margin-right: 20px;
  }
  h1.team-name {
    @include text(600, 24px, 24px, $spn-900-color);
    letter-spacing: 0.2px;
    margin-top: 28.22px;
  }

  h6.team-leader {
    @include text(600, 14px, 16px, $spn-700-color);
    letter-spacing: 0.002em;
  }

  .header-select {
    width: 160px;
    float: right;
  }

  .progress {
    border-radius: 2px;
  }
  .td-progress {
    width: 112px;
    height: 8px;

    /* SPN 100 */

    background: #d7dce0;
    border-radius: 2px;
  }
  .radius {
    border-radius: 2px;
  }

  .select-container {
    width: 168px;

    .css-veben7-MenuList {
      height: 200px !important;
    }
  }

  .label {
    @include text(600, 18px, 24px, $spn-700-color);
    letter-spacing: 0.25px;
  }
  .label > span {
    @include text(600, 14px, 16px, $spn-700);
  }
  .label-text {
    letter-spacing: 0.4px;
    margin-top: 8px;
    @include text(normal, 12px, 16px, $spn-700);
  }

  .search-row {
    margin-top: 32px;
    .search-container {
      width: 360px;
    }
  }

  .empty-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 100px;
    .no-teams {
      margin: 12px 0 8px 0;
      @include text(600, 16px, 24px, $spb-800-color);
    }
    .add-team {
      @include text(normal, 16px, 16px, $spb-700-color);
    }
  }

  table {
    margin-top: 24px;
    thead {
      @include text(600, 14px, 16px, $spn-400-color);
      border-bottom: 1px solid $spn-200-color;
    }
    .title-header {
      width: 50%;
    }
  }

  .insight-info {
    span:first-child {
      @include text(600, 24px, 16px, $spn-700-color);
    }
    span:nth-child(2) {
      @include text(600, 14px, 16px, $spn-700-color);
      margin-top: 6px;
    }
    margin-bottom: 8px;
  }
}
