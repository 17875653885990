@import "../../../assets/common/mixins.scss";
@import "../../../assets/common/partials/colors";

.step-container {
  position: fixed;
  width: 400px;
  top: 40px;
  left: 20px;
  .close-step {
    cursor: pointer;
  }
  .step-content {
    .step-head {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      background: $spb-100-color;
      padding: 23px 16px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      .title {
        img {
          height: 24px;
          width: 24px;
        }
        letter-spacing: 0.25px;
        @include text(600, 18px, 24px, $spn-900-color);
      }
      .subtitle {
        letter-spacing: 0.4px;
        margin: 8px 0 12px 0;
        @include text(normal, 14px, 16px, $spn-700-color);
      }
      .progress-count {
        display: flex;
        align-items: center;
        @include text(600, 14px, 16px, $spn-700-color);
        .count {
          margin-right: 16px;
        }
      }
      .progress {
        height: 8px !important;
        width: 100% !important;
      }
    }
    .instruction-content {
      padding: 20px 16px;
      background-color: $white;
      .instruction {
        cursor: pointer;
        letter-spacing: 0.4px;
        display: flex;
        padding: 16px 0 12px 0;
        align-items: center;
        justify-content: space-between;
        @include text(normal, 14px, 16px, $spn-500-color);
        border-bottom: 1px solid $spn-100-color;
        .instruction-count {
          height: 24px;
          width: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid $spb-500-color;
          border-radius: 50%;
          margin-right: 8px;
        }
        .step-text {
          display: flex;
          align-items: center;
          img {
            display: inline-block;
            margin-right: 8px;
          }
        }
      }
      .instruction:last-child {
        border-bottom: none;
      }
      .line-through {
        text-decoration-line: line-through;
      }
    }
    background: $white;
    box-shadow: 0px 4px 4px rgba(215, 220, 224, 0.4);
    border-radius: 4px;
  }
  .get-started {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 152px;
    padding-top: 8px;
    padding-bottom: 8px;
    background: $spb-800-color;
    border-radius: 20px;
    letter-spacing: 0.2px;
    margin-top: 16px;
    @include text(600, 16px, 24px, $white);
    img {
      margin-left: 5px;
    }
  }
}
