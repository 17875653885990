@import "../../../assets/common/mixins.scss";
@import "../../../assets/common/partials/colors";

.company-insights-container {
  .logged {
    @include text(500, 14px, 16px, $spn-500-color);
    margin-bottom: 12px;
  }

  .insight-info {
    span:first-child {
      @include text(600, 24px, 16px, $spn-700-color);
    }
    span:nth-child(2) {
      @include text(600, 14px, 16px, $spn-700-color);
      margin-top: 6px;
    }
    margin-bottom: 8px;
  }

  .search-contain {
    width: 184px;
  }

  .chart-filter {
    position: absolute;
    right: 0;
    top: 16px;
    width: 144px;
  }

  .apexcharts-toolbar {
    z-index: 500 !important;
  }
  .summary {
    @include text(500, 12px, 16px, $spn-700-color);
    letter-spacing: 0.4px;
    .greater-than {
      color: $spg-600-color;
    }
    .less-than {
      color: $spr-500-color;
    }
  }
  .three {
    @include text(600, 14px, 16px, $spn-600-color);
  }

  .more {
    @include text(500, 12px, 16px, $spn-700-color);
  }

  .link-text {
    text-decoration: underline;
    @include text(500, 14px, 16px, $spb-600-color);
  }

  .custom-contain {
    width: 272px;
    float: right;
  }

  .cycle-select-container {
    width: 184px;
  }

  .cycle-custom-select-container {
    min-width: 264px;
    margin-left: auto;
  }

  .tb-select-container {
    width: 120px;
    margin-left: 16px;
  }

  .chart-container {
    border-bottom: 1px solid $offWhite;
    margin-bottom: 24px;
    border-top: 1px solid $offWhite;
    margin-top: 16px;
    padding-top: 24px;
    width: 100%;
  }

  .bt-page {
    border-top: 1px solid $offWhite;
    margin-top: 16px;
    padding-top: 24px;
  }

  .border-below {
    border-bottom: 1px solid $offWhite;
    justify-content: space-between;
  }

  .loader-contain {
    height: 280px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f9f9f9b5;
  }
}
