@import "../../../assets/common/mixins.scss";
@import "../../../assets/common/partials/colors";

.home-goal-container {
  .cycle-select {
    width: 160px;
    height: 40px;
    margin-right: 16px;
  }
  .goal-action {
    @include text(normal, 16px, 16px, $spn-700-color);
  }
  .btns {
    display: flex;
    .add-key {
      height: 40px;
    }
    .cycle-select {
      max-height: 16px;
    }
  }
  .title {
    margin-bottom: 24px;
    letter-spacing: 0.25px;
    @include text(600, 18px, 24px, $spb-900-color);
  }
  .td-title {
    margin-bottom: 8px;
    @include text(500, 14px, 16px, $spn-900-color);
  }
  .td-subtitle {
    @include text(normal, 12px, 16px, $spn-500-color);
  }
  .td-date {
    letter-spacing: 0.4px;
    @include text(normal, 14px, 16px, $spn-900-color);
  }
  .td-status {
    cursor: pointer;
    text-decoration-line: underline;
    @include text(600, 14px, 16px, $spb-600-color);
  }

  .label {
    @include text(600, 18px, 16px, $spn-700);
  }
  .label > span {
    @include text(600, 14px, 16px, $spn-700);
  }
  .label-text {
    letter-spacing: 0.4px;
    margin-top: 8px;
    @include text(normal, 12px, 16px, $spn-700);
  }
  .sub-items {
    margin-top: 32px;
  }

  .form-control {
    height: 40px !important;
  }
  table {
    margin-top: 24px;
    thead {
      background-color: $white;
      @include text(600, 14px, 16px, $spn-400-color);
    }
    .title-header {
      width: 50%;
    }
  }
  .empty-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 100px;
    .no-goals {
      margin: 12px 0 8px 0;
      @include text(600, 16px, 24px, $spb-800-color);
    }
    .add-goal {
      @include text(normal, 16px, 16px, $spb-700-color);
    }
  }
}

.submit-review {
  align-items: center;
  text-decoration-line: underline;
  @include text(600, 14px, 16px, $spb-600-color);
}
.pending,
.disapprove-status {
  display: inline-block;
  padding: 8px;
  border-radius: 4px;
  letter-spacing: 0.4px;
}

.pending {
  background: $spn-050-color;
  @include text(normal, 14px, 16px, $spb-700-color);
}

.disapprove-status {
  display: inline-block;
  margin-right: 8px;
  background: $spr-100-color;
  @include text(normal, 14px, 16px, $spr-600-color);
}
.progress {
  width: 168px;
}
.approved {
  display: flex;
  align-items: center;
  img {
    cursor: pointer;
  }
  .progress-bar {
    height: 8px;
    border-radius: 3px;
    .progress {
      width: 112px;
      border-radius: 0;
    }
  }
  .percent-count {
    display: inline-block;
    margin-left: 24px;
    margin-right: 18px;
    text-decoration-line: none;
    @include text(600, 14px, 16px, $spn-700-color);
  }
}
