@import "../../../assets/common/colors.scss";
.tb-paginator {
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .pg-count {
    display: flex;
    height: 24px;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #24292e;
  }

  .pg-nav {
    display: flex;
    list-style-type: none;
    flex-direction: row;
    align-items: flex-start;
    padding: 13px;
    padding-right: 0px;
    font-size: 14px;
    height: 24px;
    margin-bottom: 0;

    li {
      display: flex;
      flex-direction: column;
      padding: 5px 8px;
      // height: 24px;
      border-radius: 2px;
      flex: none;
      align-self: center;
      margin: 8px 0px;
    }
    .item-active {
      background: $spb-100-color;

      a {
        &:focus {
          border: none !important;
          outline: none !important;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .tb-paginator {
    height: 40px;
    display: flex;
    flex-direction: column;
  }
}
