.adjust-left {
  padding-left: 10.5px !important;
}

.duration {
  font-size: 14px;
}

.flatpicker-wrapper {
  width: 100%;
  background: #ffffff;
  border: 1px solid #d7dce0;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px 12px;
  color: #3a434b;
  font-size: 14px;

  &:disabled {
    background-color: #f4f5f6;
  }
}

.h-40 {
  height: 2.5rem !important;
}

.h-48 {
  height: 3rem !important;
}

.flatpicker-wrapper:focus {
  outline: transparent;
}

.flatpickr-calendar.open {
  // padding: 0 24px 24px 24px;
  background: #ffffff;
  width: 312px;
}

.flatpickr-calendar {
  &:before,
  &:after {
    display: none !important;
  }
}

.flatpickr-months {
  padding-top: 16px;
  background: #ffffff;
}

.flatpickr-months .flatpickr-prev-month {
  padding-left: 54px;
}

.flatpickr-months .flatpickr-next-month {
  padding-right: 54px;
}

.flatpickr-months .flatpickr-month {
  background: #ffffff;
  height: 16px;
}

.flatpickr-current-month {
  background: #fff;
  color: #0d0f11;
  font-size: 14px;
  letter-spacing: 0.4px;
  padding: 0;

  .cur-month {
    font-weight: normal !important;
  }
}

.today {
  color: #0f42a4 !important;
  border: none !important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: #ffffff;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  background: #ffffff;
}

.flatpickr-rContainer {
  padding-top: 8px;
}

.flatpickr-weekdays {
  background: #ffffff;
  padding-bottom: 10px;
  letter-spacing: 0.2px;
  color: #0d0f11;
}

span.flatpickr-weekday {
  background: #ffffff;
  font-size: 10px;
}

.flatpickr-days {
  border: none;
  border-top: 1px solid #d7dce0 !important;
}

.flatpickr-day {
  max-width: 24px;
  height: 24px;
  line-height: 24px;
  margin: 8px;
}

.dayContainer {
  font-size: 12px;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #1354d3 !important;
  color: #fff !important;
}

.flatpickr-next-month:hover svg {
  color: #0d0f11 !important;
  fill: #0d0f11 !important;
  // stroke-width: 4em !important;
}

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: 10px;
  height: 30px;
  color: #0f42a4;
  fill: #0f42a4;
}

.flatpickr-months .flatpickr-prev-month svg:hover,
.flatpickr-months .flatpickr-next-month svg:hover {
  color: #0d0f11 !important;
  fill: #0d0f11 !important;
}
