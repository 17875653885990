@import "../../../../assets/common/mixins.scss";
@import "../../../../assets/common/colors.scss";
.multiple-teams-container {
  .ri-folder-fill {
    color: $spb-400-color;
    font-size: 36px;
  }
  .ri-checkbox-circle-fill {
    color: $spg-600-color;
    font-size: 20px;
  }

  .horizontal {
    height: 0px;
    margin-top: 1em;
    width: 94%;
    margin-left: auto;
    margin-right: auto;
    background-color: $offWhite;
  }
}
