#controlled-tab-settings-tab-Profile,
#controlled-tab-settings-tab-Teams,
#controlled-tab-settings-tab-Users,
#controlled-tab-settings-tab-Time,
#controlled-tab-settings-tab-Notifications,
#controlled-tab-settings-tab-Goals,
#controlled-tab-settings-tab-One-on-One,
#controlled-tab-settings-tab-people,
#controlled-tab-settings-tab-insights,
#controlled-tab-settings-tab-objectives,
#controlled-tab-settings-tab-teams,
#controlled-tab-settings-tab-Objectives,
#controlled-tab-settings-tab-Reports,
#controlled-tab-settings-tab-Members,
#controlled-tab-settings-tab-Insights {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-right: 32px !important;
}
