@import "../../../assets/common/colors.scss";
.action-dropdown {
  width: 200px;
  border: 1px solid $offWhite;
  box-sizing: border-box;
  border-radius: 4px;

  .dropdown-item {
    width: 200px;
    height: 40px;
    left: 0px;
    top: 0px;
    &:hover {
      background: $sideNavBgColor;
    }
  }
}
.ri-more-2-fill {
  color: $spb-600-color;
}

.dd-icon {
  cursor: pointer;
}
