@import "../../../assets/common/mixins.scss";
@import "../../../assets/common/partials/colors";

.login-form-container {
  background: $spn-050-color;
  min-height: 100vh;
  padding: 30px 0 50px 0;
  .logo {
    text-align: center;
    margin-bottom: 48px;
  }
  .login-content {
    padding: 32px 32px 41px 32px;
    margin: auto;
    width: 528px;
    background-color: $white;
    .form-control {
      border: none !important;
    }
    .form-field {
      border: 1px solid $offWhite !important;
    }
    .layout-style {
      position: relative;
    }
    label,
    .css-1wa3eu0-placeholder {
      @include text(normal, 14px, 16px, $spn-700-color);
    }
    .forgot-password {
      text-align: right;
      text-decoration-line: underline;
      margin-bottom: 32px;
      cursor: pointer;
      @include text(600, 14px, 16px, $spb-600-color);
    }
    .already {
      margin-top: 25px;
      text-align: center;
      @include text(normal, 16px, 16px, $spn-700-color);
      letter-spacing: -0.05px;
      .link-text {
        cursor: pointer;
        color: $spb-600-color;
      }
    }
    .btn-goal {
      width: 100%;
    }
    .passwordToggle {
      position: absolute;
      right: 30px;
      margin-top: 45px;
      cursor: pointer;
      @include text(normal, 16px, 16px, $spb-600-color);
    }
    .create-account {
      text-align: center;
      @include text(600, 24px, 24px, $spn-900-color);
    }
    .create-account-subtitle {
      text-align: center;
      margin: 16px 0 32px 0;
      @include text(normal, 16px, 24px, $spn-700-color);
    }
    .go-back {
      text-align: center;
      margin-top: 24px;
      @include text(600, 16px, 16px, $spb-600-color);
    }
  }
  .onsuccess-password-reset {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 46px 0;
    .square {
      height: 96px;
      width: 96px;
      background: $spn-100-color;
      border-radius: 4px;
    }
    .check-your-email {
      margin-top: 32px;
      margin-bottom: 16px;
      @include text(600, 24px, 24px, $spn-900-color);
    }
    .description {
      @include text(normal, 16px, 16px, $spn-700-color);
    }
  }
  .go-back {
    cursor: pointer;
  }
}
