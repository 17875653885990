@import "../../../assets/common/mixins.scss";
@import "../../../assets/common/partials/colors";

.main-nav-container {
  display: flex;
  justify-content: flex-end;
  text-align: right;
  padding: 16px 10px;
  margin-bottom: 32px;
  .avatar {
    padding: 0 10px 0 18px;
  }
  .name {
    margin: 16px 0 8px 0;
    padding-left: 16px;
    @include text(600, 14px, 16px, $spb-700-color);
  }
  .role {
    letter-spacing: 0.4px;
    padding-bottom: 8px;
    padding-left: 16px;
    border-bottom: 1px solid $spn-100-color;
    @include text(normal, 12px, 16px, $spn-700-color);
  }
  .my-profile {
    cursor: pointer;
    padding: 12px 0 12px 16px;
    @include text(normal, 14px, 16px, $spn-700-color);
  }
  .sign-out {
    cursor: pointer;
    padding: 12px 0 12px 16px;
    @include text(normal, 14px, 16px, $spn-700-color);
  }
  .list-unstyled {
    padding: 0;
    margin: 0;
  }
  .dropdown-menu {
    width: 200px;
    padding: 0;
    margin-top: 9px !important;
  }
}
