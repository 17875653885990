.company-teams-container {
  table thead th {
    border-bottom: 1px solid #bac2ca;
  }
  .search-contain {
    width: 304px;

    .search-bar {
      margin-right: 0 !important;
    }
  }

  .pg-header {
    margin-bottom: 16px;
  }

  .action > span {
    cursor: pointer;
  }

  .tip.left {
    right: 125%;
  }
}
