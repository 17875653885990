@import "../../../assets/common/mixins.scss";
@import "../../../assets/common/partials/colors";

.company-profile-container {
  .title {
    @include text(600, 16px, 24px, $spn-900);
    letter-spacing: 0.2px;
  }
  .detail-update {
    display: flex;
    justify-content: space-between;
  }
  .title {
    @include text(600, 16px, 24px, $spn-900-color);
    letter-spacing: 0.2px;
  }
  label {
    margin-top: 9px;
    @include text(normal, 14px, 16px, $spn-700-color);
  }
}
