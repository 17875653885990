.accordion-container {
  margin-bottom: 15px;
}

// .accordion .card {
//     border: none;
// }

// .accordion .card .card-header > button {
//  text-decoration: none;
//  padding: 0;
// }

// .accordion > .card > .card-header {
//     border-bottom: none;
//     background: none;
//     height: 72px;
// }

// .collapse .card-body {
//     border-left: 2px solid #D7DCE0;
//     padding: 0 1.25rem;
// }
