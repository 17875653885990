@import "../../../assets/common/colors.scss";
.custom-chip-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 12px;

  // minwidth: 228px;
  height: 32px;
  background: $sideNavBgColor;
  border-radius: 16px;
  margin-right: 8px;
  .remove {
    cursor: pointer;
  }
}
