.cl-margin-padding {
  margin: 0;
  padding: 0;
}

.child-row td:first-child {
  padding-left: 35px !important;
}

.grand-child-row td:first-child {
  padding-left: 55px !important;
}
