@import "../../../../../assets/common/colors.scss";
@import "../../../../../assets/common/variables.scss";

#member-editor {
  .team-editor-content {
    width: 560px !important;
  }

  .checkbox-label {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: $primaryColor;
    margin-bottom: 0;
  }

  .modal-body {
    padding: 0 24px;
  }

  .mb-11 {
    margin-bottom: 11px;
  }
  .mt-24 {
    margin-top: 24px;
  }
  .mb-30 {
    margin-bottom: 30px;
  }
  textarea.form-control {
    height: 136px !important;
    &::placeholder {
      padding-top: 16px !important;
    }
  }
}
