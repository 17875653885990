@import "../../../assets/common/mixins.scss";
@import "../../../assets/common/partials/colors";

.one-on-one-content {
  .no-goals {
    margin: 12px 0 8px 0;
    @include text(600, 16px, 24px, $spb-800-color);
  }
  .add-goal {
    @include text(normal, 16px, 16px, $spn-700);
  }
  .empty-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    place-content: center;
    height: 100%;
    width: 100%;
    border: 1px solid $spn-100-color;
    box-sizing: border-box;
    border-radius: 4px;
  }
  .empty-content-log {
    height: 100%;
    width: 100%;
    border: 1px solid $spn-100-color;
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 100px;

    .file-message {
      margin-top: 128px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .item-content {
    border: 1px solid $spn-100-color;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 24px;
    min-height: 100%;
    .summary,
    .month-summary {
      @include text(600, 16px, 24px, $spn-900-color);
    }
    .month-summary {
      margin-bottom: 16px;
    }
    .desc {
      margin-top: 9px;
      @include text(normal, 16px, 24px, $spn-700-color);
    }
    label {
      display: block;
      margin: 16px 0 8px 0;
      @include text(normal, 14px, 16px, $spn-700-color);
    }
    textarea {
      margin-bottom: 24px;
    }

    .narration {
      padding-bottom: 16px;
      @include text(normal, 16px, 24px, $spn-700-color);
    }

    .manager-comment {
      border-top: 1px solid $spn-100-color;
      padding-top: 24px;
      padding-bottom: 8px;
      @include text(600, 14px, 16px, $spn-800-color);
    }
  }
  .search-content {
    height: 632px;
    width: 264px;
    overflow-y: auto;
    .present-content,
    .past-content {
      .name {
        margin: 24px 0 16px 0;
        @include text(600, 14px, 16px, $spn-700-color);
      }
      .monthly {
        letter-spacing: 0.4px;
        @include text(normal, 14px, 16px, $spn-900-color);
        padding: 12px 12px 12px 16px;
        background: $spn-050-color;
        border-radius: 4px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }
    .present-content {
      padding-bottom: 16px;
      margin-right: 16px;
      border-bottom: 1px solid $spn-200-color;
      .monthly {
        display: flex;
        justify-content: space-between;
        .dot {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: $spb-500-color;
        }
      }
    }

    .past-content {
      .monthly {
        margin-right: 16px;
      }
    }
  }
  .title-empty-log {
    padding: 24px;
    @include text(600, 16px, 24px, $spn-900-color);
  }
}
