.okr-rpt-container {
  .search-container {
    width: 304px;
  }
  .select-container {
    width: 168px;
  }
  .css-veben7-MenuList {
    height: 160px !important;
  }

  .progress {
    border-radius: 2px;
  }
}
