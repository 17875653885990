@import "../../../assets/common/mixins.scss";
@import "../../../assets/common/partials/colors";

.side-modal-form-content,
.side-modal-content {
  position: relative;
  padding: 0;
  margin: 0;
  .modal-dialog {
    min-width: 100vw;
    min-height: 100vh;
    margin-top: 0;
    padding: 0;
    position: position;
    top: 0;
    right: 0;
  }
  .title {
    display: flex;
    padding: 0 24px 0 24px;
    margin-top: -25px;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    min-width: 100%;
    img {
      height: 13px;
      width: 13px;
      cursor: pointer;
    }
    @include text(normal, 18px, 24px, $spn-900-color);
    border-bottom: 1px solid $spn-100-color;
  }
}
.side-modal-form-content {
  .modal-content {
    position: absolute;
    margin: 0;
    width: 480px;
    border-radius: 0;
    // padding: 0;
    right: 0;
    padding-top: 24px;
  }
}
.side-modal-content {
  .modal-content {
    position: absolute;
    margin: 0;
    max-width: 724px;
    border-radius: 0;
    // padding: 0;
    right: 0;
    padding-top: 24px;
  }
}
.side-modal-edit-content {
  .modal-content {
    position: absolute;
    margin: 0;
    width: 480px !important;
    border-radius: 0;
    // padding: 0;
    right: 0;
    padding-top: 24px;
    background-color: red;
  }
}
