@import "../../../../assets/common/mixins.scss";
@import "../../../../assets/common/partials/colors";

.all-objectives-container {
  .pg-header {
    margin-bottom: 8px;
  }
  .objective-action {
    @include text(normal, 16px, 16px, $spn-700-color);
  }

  .td-title {
    @include text(500, 14px, 16px, $spn-900-color);
  }

  .td-date {
    letter-spacing: 0.4px;
    @include text(normal, 14px, 16px, $spn-900-color);
  }
  .td-status {
    cursor: pointer;
    text-decoration-line: underline;
    @include text(600, 14px, 16px, $spb-600-color);
  }

  .label {
    @include text(600, 18px, 16px, $spn-700);
    display: flex;

    .mr {
      margin-right: 9.33px;
    }
  }
  .label > span {
    @include text(600, 14px, 16px, $spn-700);
  }
  .label-text {
    letter-spacing: 0.4px;
    margin-top: 8px;
    @include text(normal, 12px, 16px, $spn-700);
  }
  .sub-items {
    margin-top: 32px;
  }

  .form-control {
    height: 40px !important;
  }

  .empty-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 100px;
    .no-objectives {
      margin: 12px 0 8px 0;
      @include text(600, 16px, 24px, $spb-800-color);
    }
    .add-objective {
      @include text(normal, 16px, 16px, $spb-700-color);
    }
  }

  .tip.left {
    right: 125%;
  }
}
