.view-team-member-container {
  .team-member {
    min-width: 250px;
  }

  .header-container {
    margin-bottom: 16px;
  }

  .search-contain {
    width: 304px;
  }

  .blue-color {
    color: #1354d3;
  }

  .red-color {
    color: #e43535;
  }
}
