@import "../../../assets/common/partials/spacing";
@import "../../../assets/common/partials/colors";
@import "../../../assets/common/partials/fonts";

/***************************************************************
************* LOZENGES ***************
****************************************************************/
.sp-lozenge {
  font-size: $sp-fontsize--14;
  align-items: center;
  justify-content: center;
  line-height: $sp-lineheight--16;
  padding: $padding-4 $padding-8;
  border-radius: $br-4;
  height: 24px;
  border: none;

  &--default {
    color: $spn-700-color;
    background-color: $spn-100-color;
  }

  &--success {
    color: $spg-500-color;
    background-color: $spg-100-color;
  }

  &--failed {
    color: $spr-500-color;
    background-color: $spr-100-color;
  }

  &--pending {
    color: $spo-500-color;
    background-color: $spo-100-color;
  }

  &--notStarted {
    color: $spr-300-color;
    background-color: $spr-100-color;
  }

  &--behind {
    color: $spy-600-color;
    background-color: $spy-100-color;
  }

  &--onTrack {
    color: $spg-600-color;
    background-color: $spg-100-color;
  }

  &--atRisk {
    color: $spr-600-color;
    background-color: $spr-100-color;
  }

  &--completed {
    color: $spt-600-color;
    background-color: $spt-100-color;
  }
}
