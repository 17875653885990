@import "../../../assets/common/mixins.scss";
@import "../../../assets/common/partials/colors";

#company-objectives-container {
  table {
    margin-top: 24px;
    thead {
      background-color: $white;
      @include text(600, 14px, 16px, $spn-400-color);
      border-bottom: 1px solid #d7dce0;
      border-top: 1px solid #d7dce0;
      tr {
        height: 48px;
      }
    }
  }

  .header-select {
    width: 160px;
    height: 40px;
    // margin-right: 16px;
  }
  .search-container {
    width: 360px;
  }
  .level2 {
    margin-left: 15px;
  }
  .level3 {
    padding-left: 60px !important;
  }
  .level2,
  .level3 {
    border-left: 1px solid $spn-100-color;
    min-height: 72px;
    display: inline-block;
    margin-left: 15px;
    margin-bottom: -45px !important;
  }

  .progress-bar {
    border-radius: 2px;
    margin-right: 2px;
  }

  .arrow-contain {
    margin-right: 20px;
  }
  .td-avatar {
    padding-top: 10px !important;
  }
  .team-icon {
    margin-left: 10px;
  }
  .search-contain {
    width: 360px;
  }
  .progressBar {
    .progress {
      width: 168px;
      height: 16px;
      background-color: #fff !important;
      border-radius: 2px;
    }
  }
  .td-progress {
    border-radius: 2px;
  }
  .avg-progress {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #3a434b;
    border-radius: 2px;
  }
  .progress {
    width: 112px;
    height: 8px;
    background-color: #d7dce0;
    border-radius: 2px;
    .progress-bar {
      margin-right: 2px;
      border-radius: 2px;
    }

    &:last-child {
      margin-right: 0 !important;
    }
  }
  .ri-arrow-right-s-line {
    color: #1354d3;
    font-weight: bold;
  }
  .label-text {
    letter-spacing: 0.4px;
    margin-top: 8px;
    @include text(normal, 12px, 16px, $spn-700-color);
  }

  .form-control {
    height: 40px !important;
  }
  .img-td {
    padding-top: 14px !important;
  }
}
