@import "../../../../../assets/common/mixins.scss";
@import "../../../../../assets/common/partials/colors";

.objective-form-content {
  .form-control {
    border: none !important;
  }
  .form-field {
    border: 1px solid $offWhite !important;
  }
  .layout-style {
    padding-left: 22px;
    padding-right: 22px;
  }

  .btn-goal {
    width: 100%;
  }
  .border-btm {
    color: $spn-100-color;
    margin-bottom: 32px;
    // margin-left: 24px;
  }
  .alignment {
    margin: 12px 0 16px 0;
    // @include text(600, 14px, 16px, $spn-700-color);
  }
  .progress-track {
    margin: 32px 0 16px 0;
    @include text(600, 14px, 16px, $spn-700-color);
  }
  .align-goal {
    border-bottom: 1px solid $spn-100-color;
  }
  .align-goal,
  .add-key-result {
    text-decoration-line: underline;
    @include text(600, 14px, 16px, $spb-600-color);
    padding-bottom: 16px;
  }
  label,
  .css-1wa3eu0-placeholder {
    @include text(normal, 14px, 16px, $spn-700-color);
  }
}
