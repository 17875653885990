@import "../../../assets/common/partials/spacing";
@import "../../../assets/common/partials/colors";
@import "../../../assets/common/partials/fonts";

/***************************************************************
************* BADGES ***************
****************************************************************/
.sp-badge {
  font-size: $sp-fontsize--14;
  align-items: center;
  justify-content: center;
  line-height: $sp-lineheight--16;
  padding: $padding-4 $padding-8;
  border-radius: $br-12;
  height: 24px;
  border: none;

  &--primary {
    color: $spn-000-color;
    background-color: $spb-500-color;
  }

  &--important {
    color: $spn-000-color;
    background-color: $spr-500-color;
  }

  &--added {
    color: $spg-500-color;
    background-color: $spg-100-color;
  }

  &--removal {
    color: $spr-500-color;
    background-color: $spr-100-color;
  }
}
