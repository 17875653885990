@import "../../../../../../assets/common/mixins.scss";
@import "../../../../../../assets/common/partials/colors";

.view-details-container {
  .navigate-icon {
    cursor: pointer;
  }
  .kr-title {
    @include text(600, 16px, 24px, $spn-900-color);
  }
  .kr-team-info {
    img {
      margin-right: 10px;
    }
    margin: 8px 0;
    @include text(normal, 12px, 16px, $spn-700-color);
  }
  .progress-summary .progress {
    height: fit-content;
    width: 100%;
  }

  .kr-section-title {
    @include text(600, 14px, 16px, $spn-700-color);
    padding: 24px 0;
    line-height: 16px;
    letter-spacing: 0.002em;
  }

  .key-result-content {
    .key-result-title {
      @include text(500, 14px, 16px, $spn-900-color);
      letter-spacing: 0.002em;
      margin-bottom: 8px;
    }

    .key-result-date {
      @include text(normal, 12px, 16px, $spn-900-color);
      display: flex;
      align-items: center;
      letter-spacing: 0.4px;
      margin-bottom: 24px;
    }
  }

  .no-data {
    font-size: 12px;
    font-style: italic;
    font-weight: 200;
  }

  .approved,
  .progress-summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
    border-bottom: 1px solid $spn-100-color;
    .progress-bar {
      height: 8px;
      border-radius: 3px;
    }
    .percent-count {
      display: inline-block;
      margin-left: 16px;
      margin-right: 16px;
      text-decoration-line: none;
      @include text(600, 14px, 16px, $spn-700-color);
    }
  }

  .approved .progress {
    width: 194px;
    height: fit-content;
    border-radius: 0;
  }

  .progress-summary .progress {
    width: 100%;
    height: fit-content;
    border-radius: 0;
  }

  .detail-action {
    @include text(normal, 16px, 16px, $spn-700-color);
  }

  .alignment-content {
    padding-bottom: 16px;
    border-bottom: 1px solid $spn-100-color;
    .alignment {
      margin: 24px 0 16px 0;
      @include text(600, 14px, 16px, $spn-700);
    }
    .features {
      @include text(500, 14px, 16px, $spn-900-color);
      margin-bottom: 8px;
    }
    .role {
      img {
        margin-right: 10px;
      }
      @include text(normal, 12px, 16px, $spn-700-color);
    }
  }

  .contributions-content {
    padding-bottom: 16px;
    border-bottom: 1px solid #f1f4f8;
    .contributions-title {
      margin-top: 24px;
      margin-bottom: 8px;
      @include text(600, 14px, 16px, $spn-700-color);

      letter-spacing: 0.002em;
    }
    .contributing-key-result-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 16px;
      margin-top: 24px;

      .name {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        /* identical to box height, or 114% */

        letter-spacing: 0.002em;

        /* SPN 900 */

        color: #0d0f11;
      }
    }
  }
  .owner-content {
    padding-bottom: 16px;
    border-bottom: 1px solid $spn-100-color;
    .owner {
      margin: 24px 0 16px 0;
      @include text(600, 14px, 16px, $spn-700-color);
    }

    .name {
      img {
        margin-right: 10px;
      }
      @include text(normal, 14px, 16px, $spn-900-color);
    }
  }

  .date-content {
    display: flex;
    padding-bottom: 16px;
    border-bottom: 1px solid $spn-100-color;
    .date-item {
      width: 50%;
    }
    .due-date {
      margin: 24px 0 16px 0;
      @include text(600, 14px, 16px, $spn-700-color);
    }
    .date {
      letter-spacing: 0.4px;
      @include text(normal, 14px, 16px, $spn-900-color);
    }
  }

  .activity-content {
    padding-bottom: 16px;
    .activity {
      margin-top: 24px;
      @include text(600, 14px, 16px, $spn-700-color);
    }
    .activity-items {
      border: 1px solid $spn-100-color;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 16px 16px 0 16px;
      margin-top: 16px;
      .desc {
        display: flex;
        justify-content: space-between;
        .desc-text {
          letter-spacing: 0.4px;
          width: 318px;
          @include text(normal, 14px, 24px, $spn-900-color);
          .activity-title {
            @include text(600, 14px, 24px, $spn-900-color);
          }
        }
        .desc-date {
          letter-spacing: 0.4px;
          margin-top: 10px;
          @include text(normal, 12px, 16px, $spn-700-color);
        }
      }
      .percent-items {
        padding-bottom: 12px;
        border-bottom: 1px solid $spn-100-color;

        .percent {
          display: inline-block;
          margin-right: 16px;
          margin-top: 12px;
          letter-spacing: 0.4px;
          @include text(normal, 14px, 16px, $spn-700-color);
          .update {
            color: $spg-700-color;
          }
          .update-yellow {
            color: $spy-500-color;
          }
        }
      }
      .comment-content {
        padding: 16px 0;
        .comment {
          display: block;
          @include text(600, 14px, 24px, $spn-900-color);
        }
        letter-spacing: 0.4px;
        @include text(500, 14px, 24px, $spn-700-color);
        .file-content {
          display: flex;
          justify-content: space-between;
          padding: 8px 18px;
          margin-top: 12px;
          background: $spn-050-color;
          border-radius: 4px;
          .file-details {
            display: flex;
            img {
              margin-right: 10px;
            }
            .file-size {
              @include text(normal, 12px, 16px, $spn-500-color);
            }
            .file-name {
              @include text(normal, 14px, 16px, $spn-700-color);
            }
          }
        }
      }
    }
  }
}
