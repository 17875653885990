@import "../../../assets/common/mixins.scss";
@import "../../../assets/common/partials/colors";

.sidebar-content {
  height: 100vh;
  overflow: scroll;
  .row {
    padding: 0;
    > .col {
      padding: 24px;
    }
  }
}
