@import "../../../../assets/common/colors.scss";
@import "../../../../assets/common/variables.scss";
@import "../../../../assets/common/mixins.scss";

.sp-dropdown-container {
  color: $primaryColor;
  font-family: "Inter", "sans-serif" !important;
}
.sp-dropdown-header {
  @include text(normal, 16px, 16px, $textColor);
  left: 0%;
  right: 0%;
  background: #fff;
  border: 1px solid $offWhite;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  padding: 12px 10px 12px 16px;
  height: 40px;
  white-space: nowrap;
  text-overflow: ellipsis;

  .sp-dropdown-title {
    @include text(normal, 16px, 16px, $textColor);
    display: flex;
    align-items: center;
    letter-spacing: -0.05px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.sp-dropdown-list {
  width: auto;
  height: auto;
  border: 1px solid $offWhite;
  box-sizing: border-box;
  border-radius: 4px;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: absolute;
  z-index: 10001;
}

.sp-dropdown-list-item {
  width: 200px;
  height: 40px;
  background: #fff;
  color: $primaryColor;
  border: none;
  position: relative;
  &:hover {
    background: $sideNavBgColor;
  }
}

.sp-list-item-selected {
  background: $sideNavBgColor;
  border: none;
}

.sp-dropdown-action {
  color: $spb-700-color;
  background-color: $spb-100-color;
  border: none;
  border-radius: 4px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.css-1mrdwxz-control {
  border-color: $offWhite !important;
}
