@import "../../../assets/common/mixins.scss";
@import "../../../assets/common/partials/colors";

.overview-color-container {
  .color-block {
    width: 12px;
    height: 12px;
    border-radius: 1px;
    margin-right: 8px;
  }

  .mb {
    margin-bottom: 8px;
  }

  .bg-atRisk {
    background-color: $spr-600-color !important;
  }

  .bg-pending {
    background-color: $spn-100-color !important;
  }

  .bg-behind {
    background-color: $spy-600-color !important;
  }

  .bg-notStarted {
    background-color: $spr-300-color !important;
  }

  .bg-onTrack {
    background-color: $spg-600-color !important;
  }

  .bg-completed {
    background-color: $spt-600-color !important;
  }
}
