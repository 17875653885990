@import "../../../../assets/common/mixins.scss";
@import "../../../../assets/common/partials/colors";

.company-team-details-container {
  .back-icon-container {
    cursor: pointer;
  }

  .td-title {
    @include text(500, 14px, 16px, $spn-900-color);
  }
  .td-subtitle {
    margin-top: 8px;
    @include text(normal, 12px, 16px, $spn-500-color);
  }

  .arrow-contain {
    margin-right: 20px;
  }
  h1.team-name {
    @include text(600, 24px, 24px, $spn-900-color);
    letter-spacing: 0.2px;
    margin-top: 28.22px;
  }

  .check-in {
    cursor: pointer;
  }

  .td-avatar {
    padding-top: 11px !important;
  }
  h6.team-leader {
    @include text(600, 14px, 16px, $spn-700-color);
    letter-spacing: 0.002em;
  }

  .header-select {
    width: 160px;
    float: right;
  }

  .progress {
    border-radius: 2px !important;
  }

  .td-progress {
    width: 112px;
    height: 8px;

    /* SPN 100 */

    background: #d7dce0;
    border-radius: 2px;
  }
  .radius {
    border-radius: 2px;
  }

  .label {
    @include text(600, 18px, 24px, $spn-700-color);
    letter-spacing: 0.25px;

    .mr {
      margin-right: 9.33px;
    }
  }
  .label > span {
    @include text(600, 14px, 16px, $spn-700);
  }
  .label-text {
    letter-spacing: 0.4px;
    margin-top: 8px;
    @include text(normal, 12px, 16px, $spn-700);
  }

  .team-icon {
    margin-left: 9.33px;
  }
  .search-row {
    margin-top: 32px;
    .search-container {
      width: 360px;
    }
  }

  .empty-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 100px;
    .no-teams {
      margin: 12px 0 8px 0;
      @include text(600, 16px, 24px, $spb-800-color);
    }
    .add-team {
      @include text(normal, 16px, 16px, $spb-700-color);
    }
  }

  table {
    margin-top: 24px;
    thead {
      background-color: $white;
      @include text(600, 14px, 16px, $spn-400-color);
      border-top: 1px solid $spn-200-color;
      border-bottom: 1px solid $spn-200-color;
    }
    .title-header {
      width: 50%;
    }
  }

  .level2 {
    margin-left: 15px;
  }
  .level3 {
    padding-left: 60px !important;
  }
  .level2,
  .level3 {
    border-left: 1px solid $spn-100-color;
    min-height: 72px;
    display: inline-block;
    margin-left: 15px;
    margin-bottom: -45px !important;
  }
}
