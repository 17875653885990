@import "../../../../assets/common/mixins.scss";
@import "../../../../assets/common/partials/colors";

.registration-form-content {
  padding: 100px 120px 50px 120px;
  .identity-signup {
    cursor: pointer;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    border: 1px solid $spg-700-color;
    box-sizing: border-box;
    border-radius: 4px;
    img {
      margin-right: 11px;
    }
    @include text(600, 16px, 24px, $spg-700-color);
  }
  .or-line {
    margin-top: 22px;
    margin-bottom: 14px;
    .hr-right,
    .hr-left {
      width: 100%;
    }
    .hr-right {
      padding-left: 17px;
    }
    .hr-left {
      padding-right: 17px;
    }
    display: flex;
    align-items: center;
    text-align: center;
    @include text(600, 16px, 16px, $spn-800-color);
  }
  .form-control {
    border: none !important;
  }
  .form-field {
    border: 1px solid $offWhite !important;
  }
  .layout-style {
    position: relative;
  }
  label,
  .css-1wa3eu0-placeholder {
    @include text(normal, 14px, 16px, $spn-700-color);
  }
  .already {
    text-align: center;
    margin-top: 25px;
    @include text(normal, 16px, 16px, $spn-700-color);
    letter-spacing: -0.05px;
    .login-text {
      color: $spb-600-color;
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .btn-goal {
    width: 100%;
  }
  .passwordToggle {
    position: absolute;
    right: 30px;
    margin-top: 45px;
    cursor: pointer;
    @include text(normal, 16px, 16px, $spb-600-color);
  }
  .create-account {
    position: relative;
    @include text(600, 24px, 24px, $spn-900-color);
    .back-icon {
      position: absolute;
      left: -50px;
      top: -5px;
      cursor: pointer;
    }
  }
  .create-account-subtitle {
    margin: 16px 0 32px 0;
    @include text(normal, 16px, 16px, $spn-700-color);
  }
}
@media screen and (max-width: 1200px) {
  .registration-form-content {
    padding: 100px 5% 50px 5%;
  }
}
@media screen and (max-width: 981px) {
  .registration-form-content {
    padding: 100px 10% 50px 10%;
  }
}
@media screen and (max-width: 767px) {
  .registration-form-content {
    .create-account,
    .create-account-subtitle {
      text-align: center;
    }
  }
}
