@import "../../../../assets/common/mixins.scss";
@import "../../../../assets/common/partials/colors";

.sidebar-content {
  .row {
    padding: 0;
    > .col {
      padding: 24px;
    }
  }
  .form-width {
    min-width: 480px;
  }
}
