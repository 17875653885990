@import "../../../assets/common/mixins.scss";
@import "../../../assets/common/partials/colors";

.time-period-container {
  .review-cycle {
    @include text(600, 16px, 24px, $spn-900);
    letter-spacing: 0.2px;
  }

  .review-desc {
    @include text(normal, 16px, 24px, $spn-700);
    max-width: 552px;
    margin-top: 8px;
  }
  .select-period {
    width: 264px;
    margin: 16px 0 24px 0;
  }

  .edit-btn {
    @include text(600, 16px, 0, $spb-600-color);
    letter-spacing: 0.2px;
  }

  .hr-line {
    margin-top: 0;
  }
  .css-1wa3eu0-placeholder {
    @include text(normal, 16px, 16px, $spn-500);
  }
  .frequency {
    @include text(normal, 16px, 16px, $spn-700);
    letter-spacing: 0.4px;
    margin-bottom: 8px;
  }
  .from-select-items {
    display: flex;
  }
  .form-content {
    margin: 16px 0 24px 0;
  }
  .from-select {
    width: 168px;
    margin: 0 8px 24px 0;
  }
  .edit-btn-content {
    display: flex;
    .cancel-btn {
      @include text(600, 16px, 24px, $spn-700);
      letter-spacing: 0.2px;
      margin-right: 16px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }
  .dash-separator {
    padding: 0 16px 8px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .review-desc {
    @include text(normal, 16px, 24px, $spn-700);
    max-width: 552px;
    margin-top: 8px;
    margin-bottom: 16px;
  }

  .title-content {
    display: flex;
    justify-content: space-between;
    margin: 32px 0 16px 0;
    .subtitle {
      @include text(600, 16px, 24px, $spb-400-color);
      letter-spacing: 0.2px;
    }
    .see-all {
      letter-spacing: 0.2px;
      cursor: pointer;
      text-decoration-line: underline;
      @include text(600, 14px, 16px, $spb-600-color);
    }
  }
  .table-container {
  }
  .empty-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 100px;
    .no-goals {
      margin: 12px 0 8px 0;
      @include text(600, 16px, 24px, $spb-800-color);
    }
    .add-goal {
      @include text(normal, 16px, 16px, $spb-700-color);
    }
  }
  .edit {
    display: inline-block;
    margin-right: 24px;
    cursor: pointer;
    @include text(normal, 14px, 16px, $spb-600-color);
  }
  .delete {
    cursor: pointer;
    @include text(normal, 14px, 16px, $spr-500-color);
  }
  .search {
    width: 304px;
    height: 40px;
  }
  .search-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    .upcoming {
      cursor: pointer;
      letter-spacing: 0.25px;
      img {
        margin-right: 12px;
      }
      @include text(600, 18px, 24px, $spn-900-color);
    }
  }
  .search-input {
    display: flex;
    .search {
      display: inline-block;
      margin-right: 16px;
      position: relative;
      .form-control {
        height: 40px !important;
      }
      .search-icon {
        position: absolute;
        top: 12px;
        right: 10px;
      }
    }
  }
}
