@import "./../../../assets/common/colors.scss";

.title-page-container {
  display: flex;
  align-items: center;
  width: 100%;
  .go-back-nav {
    cursor: pointer;
    margin-right: 12px;
    margin-bottom: 2px;
    img {
      height: 16px;
      width: 16px;
    }
  }
}
