@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700&display=swap");

$sp-primary-font: "Inter", sans-serif;

$sp-fontsize--56: 56px;
$sp-fontsize--48: 48px;
$sp-fontsize--36: 36px;
$sp-fontsize--28: 28px;
$sp-fontsize--24: 24px;
$sp-fontsize--18: 18px;
$sp-fontsize--16: 16px;
$sp-fontsize--14: 14px;
$sp-fontsize--12: 12px;
$sp-fontsize--10: 10px;

//font-weight variables
$sp-fontweight--900: 900;
$sp-fontweight--800: 800;
$sp-fontweight--700: 700;
$sp-fontweight--600: 600;
$sp-fontweight--500: 500;
$sp-fontweight--400: 400;
$sp-fontweight--300: 300;
$sp-fontweight--200: 200;
$sp-fontweight--100: 100;

//line height variables
$sp-lineheight--64: 64px;
$sp-lineheight--56: 56px;
$sp-lineheight--40: 40px;
$sp-lineheight--32: 32px;
$sp-lineheight--24: 24px;
$sp-lineheight--20: 20px;
$sp-lineheight--16: 16px;
