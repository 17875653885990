@import "../common/partials/variables";
@import "../common/partials/spacing";

$tooltip-maxwidth: 235px;

/**Button Tool tip Style*/
.sp-tooltip__btn {
  position: relative;
  height: 48px;
  width: 182px;
  background: $spg-500-color;
  padding: $br-8 $br-16;
  border-radius: $br-4;
  text-align: center;
  margin: 0 auto;
  color: $spn-000-color;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  border: none;

  /**Tool tip style*/
  &::before {
    position: absolute;
    z-index: 2;
    display: none;
    max-width: $tooltip-maxwidth;
    font-size: 0.8em;
    padding: $br-8 $br-16;
    line-height: 1.4em;
    background-color: $spn-900-color;
    border-radius: $br-4;
    content: attr(title);
  }

  &::after {
    position: absolute;
    z-index: 1;
    display: none;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    content: "";
  }

  /**Hover to display the before and after pseudo-element*/
  &:hover::before,
  &:hover::after {
    display: inline-block;
  }

  /**Tool-tip top styles*/
  &.tooltip--top::before {
    bottom: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);
  }
  &.tooltip--top::after {
    bottom: 100%;
    left: 50%;
    border-top-color: $spn-900-color;
    border-bottom-width: 0;
    transform: translateX(-50%);
  }

  /**Tool-tip right styles*/
  &.tooltip--right::before {
    top: 50%;
    left: calc(100% + 5px);
    transform: translateY(-50%);
  }

  &.tooltip--right::after {
    top: 50%;
    left: 100%;
    border-right-color: $spn-900-color;
    border-left-width: 0;
    transform: translateY(-50%);
  }

  /**Tool-tip bottom styles*/
  &.tooltip--bottom::before {
    top: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);
  }

  &.tooltip--bottom::after {
    top: 100%;
    left: 50%;
    border-bottom-color: $spn-900-color;
    border-top-width: 0;
    transform: translateX(-50%);
  }

  /**Tool-tip leftt styles*/
  &.tooltip--left::before {
    top: 50%;
    right: calc(100% + 5px);
    transform: translateY(-50%);
  }

  &.tooltip--left::after {
    top: 50%;
    right: 100%;
    border-left-color: $spn-900-color;
    border-right-width: 0;
    transform: translateY(-50%);
  }
}

/**Button Tool tip Style*/
.sp-tooltip__icon {
  position: relative;
  margin: 0 auto;
  border: none;

  /**Tool tip style*/
  &::before {
    position: absolute;
    z-index: 2;
    display: none;
    max-width: $tooltip-maxwidth;
    font-size: 0.8em;
    padding: $br-8 $br-16;
    line-height: 1.4em;
    background-color: $spn-900-color;
    border-radius: $br-4;
    content: attr(title);
  }

  &::after {
    position: absolute;
    z-index: 1;
    display: none;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    content: "";
  }

  /**Hover to display the before and after pseudo-element*/
  &:hover::before,
  &:hover::after {
    display: inline-block;
  }

  /**Tool-tip top styles*/
  &.tooltip--top::before {
    bottom: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);
  }
  &.tooltip--top::after {
    bottom: 100%;
    left: 50%;
    border-top-color: $spn-900-color;
    border-bottom-width: 0;
    transform: translateX(-50%);
  }

  /**Tool-tip right styles*/
  &.tooltip--right::before {
    top: 50%;
    left: calc(100% + 5px);
    transform: translateY(-50%);
  }

  &.tooltip--right::after {
    top: 50%;
    left: 100%;
    border-right-color: $spn-900-color;
    border-left-width: 0;
    transform: translateY(-50%);
  }

  /**Tool-tip bottom styles*/
  &.tooltip--bottom::before {
    top: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);
  }

  &.tooltip--bottom::after {
    top: 100%;
    left: 50%;
    border-bottom-color: $spn-900-color;
    border-top-width: 0;
    transform: translateX(-50%);
  }

  /**Tool-tip leftt styles*/
  &.tooltip--left::before {
    top: 50%;
    right: calc(100% + 5px);
    transform: translateY(-50%);
  }

  &.tooltip--left::after {
    top: 50%;
    right: 100%;
    border-left-color: $spn-900-color;
    border-right-width: 0;
    transform: translateY(-50%);
  }
}
