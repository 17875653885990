@import "../../../../assets/common/mixins.scss";
@import "../../../../assets/common/partials/colors";

.goal-edit-form-content {
  .form-control {
    border: none !important;
  }
  .form-field {
    border: 1px solid $offWhite !important;
  }
  // .layout-style {
  //   padding-left: 24px;
  //   padding-right: 24px;
  // }
  .btn-goal-content {
    .col-6:first-child {
      padding-left: 0;
    }
    .col-6:last-child {
      padding-right: 0;
    }
    margin: 16px 0 108px 0;
  }
  .btn-goal {
    width: 100%;
  }
  .border-btm {
    color: $spn-100-color;
    margin-bottom: 32px;
    // margin-left: 24px;
  }
  .alignment {
    margin: 12px 0 16px 0;
    // @include text(600, 14px, 16px, $spn-700-color);
  }
  .alignment-item {
    @include text(500, 14px, 16px, $spn-900-color);
  }
  .progress-track {
    margin: 32px 0 16px 0;
    @include text(600, 14px, 16px, $spn-700-color);
  }
  .align-goal {
    cursor: pointer;
    border-bottom: 1px solid $spn-100-color;
  }
  .align-goal,
  .add-key-result {
    text-decoration-line: underline;
    @include text(600, 14px, 16px, $spb-600-color);
    padding-bottom: 16px;
  }
  label,
  .css-1wa3eu0-placeholder {
    @include text(normal, 14px, 16px, $spn-700-color);
  }
  .sub-title {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 16px;
    img {
      margin-right: 6px;
    }
    letter-spacing: 0.4px;
    @include text(500, 12px, 16px, $spn-500-color);
  }
}
