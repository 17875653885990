@import "../../../assets/common/mixins.scss";
@import "../../../assets/common/partials/colors";

@mixin padding-24 {
  padding-left: 24px;
  padding-right: 24px;
}
.align-key-result-container {
  padding: 16px 0 2px 0;
  .pms-select {
    width: 150px;
    margin-left: 10px;
  }
  .description {
    @include padding-24;
    @include text(normal, 16px, 24px, $spn-700-color);
  }
  .textbox {
    display: flex;
    margin-top: 24px;
    margin-bottom: 24px;
    padding-bottom: 16px;
    @include padding-24;
    border-bottom: 1px solid $spn-100-color;

    select {
      width: 160px;
      margin-left: 8px;
      height: 40px !important;
    }
    .search {
      width: 346px;
      margin-right: -16px;
      padding: 0;
    }
  }
  .content {
    border: 1px solid $spn-100-color;
    box-sizing: border-box;
    border-radius: 4px;
    margin-left: 24px;
    margin-right: 24px;
    margin: 0 24px 16px 24px;
    padding: 24px 24px 16px 24px;
    cursor: pointer;
    .content-item {
      display: flex;
      align-items: flex-start;
      .items {
        width: 100%;
      }
      .arrow-down {
        margin-top: 4px;
        margin-right: 20px;
      }
      .arrow-up {
        margin-top: 4px;
        margin-right: 20px;
      }
    }
    .radio-btn {
      margin-top: -10px;
    }
    .title {
      @include text(500, 14px, 16px, $spn-900-color);
    }
    .sub-title {
      display: flex;
      align-items: center;
      margin-top: 10px;
      img {
        margin-right: 6px;
      }
      letter-spacing: 0.4px;
      @include text(500, 12px, 16px, $spn-500-color);
    }
    .sub-content {
      border-left: 2px solid $spn-100-color;
      padding: 12px 0 12px 24px;
      margin-top: 16px;
      cursor: pointer;
      .sub-content-item {
        margin-top: 31px;
      }
    }
  }

  .keyValues {
    margin-right: 25px;
    max-height: 372px;
    overflow-y: auto;
  }
}
