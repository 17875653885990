.key-results {
  height: 64px;
  display: flex;
  align-items: flex-end;
  padding-bottom: 16px;
}

.key-results-date {
  margin: 8px 0;
  font-family: "inter";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #0d0f11;
  opacity: 1;
}

.key-results-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
}

.objectives-details {
  .approved-full .progress {
    height: fit-content;
    width: 100%;
  }

  .approved .progress {
    height: fit-content;
  }

  .approved-full .percent-count {
    line-height: 24px;
    margin-left: 17px;
    margin-right: 26px;
  }
}
