@import "../../../../assets/common/mixins.scss";
@import "../../../../assets/common/colors.scss";

.user-editor-container {
  margin: 32px 24px 36px 24px;
  .form-control {
    border-radius: 4px !important;
    border: 1px solid $offWhite !important;
    box-sizing: border-box !important;
    color: #495057;
    &:focus {
      border: 2px solid $spb-600-color !important;
      box-shadow: none !important;
    }

    .was-validated &:invalid,
    &.is-invalid {
      border-color: #dc3545 !important;
    }
  }

  .mb-24 {
    margin-bottom: 24px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .item-label {
    width: 35%;
  }
  .item-value {
    width: 65%;
  }
  .item-value,
  .item-label {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: $spn-700-color;
  }
  .form-label {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: $primaryColor;
    margin-bottom: 8px;
  }

  .form-control {
    height: 48px !important;
  }
}
